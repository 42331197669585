import {
  Table as ChakraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

interface Location {
  id: number;
  name: string;
  sensorCount: number;
}

const locations: readonly Readonly<Location>[] = [
  {
    id: 0,
    name: "Keuken",
    sensorCount: 5,
  },
  {
    id: 1,
    name: "Theater",
    sensorCount: 2,
  },
];

export default function Table() {
  return (
    <ChakraTable>
      <Thead>
        <Tr>
          <Th>Naam</Th>
          <Th># Sensoren</Th>
        </Tr>
      </Thead>
      <Tbody>
        {locations.map((location) => (
          <Tr key={location.id}>
            <Td>{location.name}</Td>
            <Td>{location.sensorCount}</Td>
          </Tr>
        ))}
      </Tbody>
    </ChakraTable>
  );
}
