import { Icon, IconProps } from "@chakra-ui/react";
import { FC } from "react";
import { MdCheckCircle, MdError, MdWarning } from "react-icons/md";
import { SensorStatus } from "./types";

const STATUS_ICONS: Readonly<Record<SensorStatus, FC>> = [
  MdError,
  MdWarning,
  MdCheckCircle,
];

const STATUS_COLORS: Readonly<
  Record<SensorStatus, "green" | "red" | "orange">
> = ["red", "orange", "green"];

export function SensorStatusDisplay({
  children: status,
  ...props
}: Omit<IconProps, "children"> & { children: SensorStatus }) {
  return (
    <Icon
      as={STATUS_ICONS[status]}
      sx={{ color: STATUS_COLORS[status] }}
      fontSize={18}
      {...props}
    />
  );
}
