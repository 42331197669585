import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";
import { FormEventHandler, ReactNode, useCallback } from "react";
import { useLocation } from "wouter";
import createFormHook, { FormFields } from "../../../common/createFormHook";
import { noopParser } from "../../../common/useFormField";
import useOrganisationId from "../useOrganisationId";
import {
  PERMISSION_LEVELS,
  PermissionLevel,
  User,
  parsePermissionLevel,
  permissionLevelNames,
} from "./types";

interface UserFormValues {
  username: string;
  email: string;
  locations: User["locations"] | undefined;
  sensors: User["sensors"] | undefined;
  tasks: User["tasks"] | undefined;
  notifications: User["tasks"] | undefined;
}

const MANDATORY_STRING = (value: string): string | undefined =>
  value.length > 0 ? undefined : "Dit veld is verplicht";

function validatePermissionLevel(
  value: PermissionLevel | undefined
): string | undefined {
  return value === undefined ? "Selecteer een waarde" : undefined;
}

const useUserForm = createFormHook<UserFormValues>(
  {
    username: "",
    email: "",
    locations: PermissionLevel.View,
    sensors: PermissionLevel.View,
    tasks: PermissionLevel.View,
    notifications: PermissionLevel.View,
  },
  {
    username: noopParser,
    email: noopParser,
    locations: parsePermissionLevel,
    sensors: parsePermissionLevel,
    tasks: parsePermissionLevel,
    notifications: parsePermissionLevel,
  },
  {
    username: MANDATORY_STRING,
    // TODO: Add email address validation
    email: MANDATORY_STRING,
    locations: validatePermissionLevel,
    sensors: validatePermissionLevel,
    tasks: validatePermissionLevel,
    notifications: validatePermissionLevel,
  }
);

function PermissionLevelControl({
  label,
  touched,
  valid,
  errorMessage,
  ...inputProps
}: { label: ReactNode } & FormFields<UserFormValues>["locations"]) {
  return (
    <FormControl isInvalid={touched && !valid}>
      <FormLabel>{label}</FormLabel>
      <Select {...inputProps}>
        {PERMISSION_LEVELS.map((permissionLevel) => (
          <option key={permissionLevel} value={permissionLevel}>
            {permissionLevelNames[permissionLevel]}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

function assertIsDefined<T extends {} | null>(value: T | undefined): T {
  if (value === undefined)
    throw new TypeError(
      `A type assertion failed. The provided value shan't be undefined.`
    );
  else return value;
}

export default function Form({
  user,
  submitLabel,
  onSubmit,
}: {
  user?: User;
  submitLabel: ReactNode;
  disableSubmit?: boolean;
  onSubmit?(user: Omit<User, "id">): void;
}) {
  const { fields, valid: formValid } = useUserForm(
    user !== undefined
      ? (() => {
          const { id, ...initialValues } = user;
          return initialValues;
        })()
      : undefined
  );

  const handleSubmit = useCallback<FormEventHandler>(
    (e) => {
      e.preventDefault();

      if (formValid) {
        onSubmit?.({
          username: fields.username.value,
          email: fields.email.value,
          locations: assertIsDefined(fields.locations.value),
          sensors: assertIsDefined(fields.sensors.value),
          tasks: assertIsDefined(fields.tasks.value),
          notifications: assertIsDefined(fields.notifications.value),
        });
      }
    },
    [formValid, onSubmit, fields]
  );

  const [, setLocation] = useLocation();
  const organisationId = useOrganisationId();

  const handleCancel = useCallback(
    () => setLocation(`/${organisationId}/users`),
    [setLocation, organisationId]
  );

  return (
    <VStack gap={1} as="form" onSubmit={handleSubmit}>
      <FormControl
        isInvalid={fields.username.touched && !fields.username.valid}
      >
        <FormLabel>Gebruikersnaam</FormLabel>
        <Input {...fields.username.inputProps} value={fields.username.value} />
        <FormErrorMessage>{fields.username.errorMessage}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={fields.email.touched && !fields.email.valid}>
        <FormLabel>E-mailadres</FormLabel>
        <Input {...fields.email.inputProps} value={fields.email.value} />
        <FormErrorMessage>{fields.email.errorMessage}</FormErrorMessage>
      </FormControl>
      <PermissionLevelControl label="Locaties" {...fields.locations} />
      <PermissionLevelControl label="Sensoren" {...fields.sensors} />
      <PermissionLevelControl label="Taken" {...fields.tasks} />
      <PermissionLevelControl label="Notificaties" {...fields.notifications} />
      <Button
        type="submit"
        isDisabled={!formValid}
        colorScheme="blue"
        width="100%"
      >
        {submitLabel}
      </Button>
      <Button onClick={handleCancel} width="100%">
        Terug
      </Button>
    </VStack>
  );
}
