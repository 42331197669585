import { Select, SelectProps } from "@chakra-ui/react";
import { orderBy } from "lodash";

export enum Language {
  Danish = "da",
  Dutch = "nl",
  English = "en",
  French = "fr",
  German = "de",
}

export const languageNames: Record<Language, string> = {
  [Language.Danish]: "Deens",
  [Language.Dutch]: "Nederlands",
  [Language.English]: "Engels",
  [Language.French]: "Frans",
  [Language.German]: "Duits",
};

export const ALPHABETICALLY_SORTED_LANGUAGES: readonly Language[] = orderBy(
  Array.from(
    (function* () {
      for (const key in languageNames)
        yield [key as Language, languageNames[key as Language]] as const;
    })()
  ),
  1
).map(([languageCode]) => languageCode);

export function isLanguage(value: string): value is Language {
  return (ALPHABETICALLY_SORTED_LANGUAGES as readonly string[]).includes(value);
}

export function parseLanguage(value: string): Language | undefined {
  return isLanguage(value) ? value : undefined;
}

export default function SelectLanguage(
  props: SelectProps & { value?: Language }
) {
  return (
    <Select {...props}>
      {ALPHABETICALLY_SORTED_LANGUAGES.map((languageCode) => (
        <option value={languageCode}>{languageNames[languageCode]}</option>
      ))}
    </Select>
  );
}
