import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Link as ChakraLink,
  Collapse,
  Stack,
  Tag,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { PropsWithChildren, useReducer } from "react";
import { useRoute } from "wouter";
import { navigate } from "wouter/use-browser-location";
import useOrganisationId from "./useOrganisationId";

function BannerItem({
  children,
  page,
  strict = false,
}: PropsWithChildren<{ page: string; strict?: boolean }>) {
  const [active] = useRoute("/:id" + page + (strict ? "" : ".*"));
  const organisationId = useOrganisationId();

  return (
    <Box>
      <ChakraLink
        onClick={() => navigate(`/organisations/${organisationId}` + page)}
        color={active ? "blue.500" : undefined}
      >
        {children}
      </ChakraLink>
    </Box>
  );
}

export default function Banner() {
  const [menuExpanded, toggleMenu] = useReducer(
    (prevState) => !prevState,
    false
  );
  const smallScreen = useBreakpointValue([true, false], {
    fallback: "lg",
  });
  const name = "De Pleats";

  const bannerItems = (
    <Stack gap={1} direction={["column", "row"]}>
      <BannerItem page="/" strict>
        Overzicht
      </BannerItem>
      <BannerItem page="/users">Gebruikers</BannerItem>
      <BannerItem page="/sensors">Sensoren</BannerItem>
    </Stack>
  );

  return (
    <Center width="100%">
      <VStack>
        <Tag fontSize="2xl" onClick={toggleMenu} size="lg">
          {smallScreen && <HamburgerIcon marginRight={1} />}
          {name}
        </Tag>
        {smallScreen ? (
          <Collapse in={menuExpanded}>{bannerItems}</Collapse>
        ) : (
          bannerItems
        )}
      </VStack>
    </Center>
  );
}
