import { Route, Router, Switch } from "wouter";
import CreationForm from "./CreationForm";
import List from "./List";
import Organisation from "./Organisation";

export default function Organisations() {
  return (
    <Router base="/organisations">
      <Switch>
        <Route path="/" component={List} />
        <Route path="/create" component={CreationForm} />
        <Route path=".*" component={Organisation} />
      </Switch>
    </Router>
  );
}
