import { SearchIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { useLocation, useSearch } from "wouter";

export function useQuery() {
  const search = useSearch();
  const query = useMemo(
    () => new URLSearchParams(search).get("query") ?? "",
    [search]
  );

  const [, setLocation] = useLocation();
  const setQuery = useCallback(
    (query: string) =>
      setLocation("?" + new URLSearchParams({ query }).toString(), {
        replace: true,
      }),
    [setLocation]
  );

  return [query, setQuery] as const;
}

export default function Search() {
  const [query, setQuery] = useQuery();

  return (
    <InputGroup colorScheme="blue">
      <InputLeftElement pointerEvents="none">
        <SearchIcon />
      </InputLeftElement>
      <Input
        maxWidth={["unset", 250]}
        placeholder="Zoeken"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
    </InputGroup>
  );
}
