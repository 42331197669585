import { useCallback } from "react";
import { useLocation } from "wouter";
import useOrganisationId from "../useOrganisationId";
import Form from "./Form";

export default function Create() {
  const [, setLocation] = useLocation();
  const organisationId = useOrganisationId();

  const goBack = useCallback(
    () => setLocation(`/${organisationId}/users`),
    [setLocation, organisationId]
  );

  return <Form submitLabel="Aanmaken" onSubmit={goBack} />;
}
