import { useReferentiallyStableMemo } from "haakje";
import { useReducer, useRef } from "react";

type FormState<Fields> = {
  [K in keyof Fields]: Readonly<{
    value: Fields[K];
    rawValue?: string;
    touched: boolean;
  }>;
};

export type FormFields<Fields> = {
  readonly [K in keyof Fields]: Readonly<{
    value: Fields[K];
    rawValue?: string;
    inputProps: {
      onChange(e: { readonly target: { readonly value: string } }): void;
      onBlur(e: { readonly target: { readonly value: string } }): void;
    };
    touched: boolean;
    valid: boolean;
    errorMessage: string | undefined;
  }>;
};

function formStateFromValues<Fields>(values: Readonly<Fields>) {
  const defaultState: Partial<FormState<Fields>> = {};

  for (let key in values)
    defaultState[key] = { value: values[key], touched: false };

  return defaultState as Readonly<FormState<Fields>>;
}

export default function createFormHook<Fields>(
  defaultValues: Readonly<Fields>,
  parsers: {
    readonly [K in keyof Fields]: (rawValue: string) => Fields[K];
  },
  validators: {
    readonly [K in keyof Fields]: (
      value: Fields[K],
      state: FormState<Fields>
    ) => string | undefined;
  }
) {
  const defaultState = formStateFromValues(defaultValues);

  const stateKeys: readonly (keyof typeof defaultState)[] = Array.from(
    (function* () {
      for (const key in defaultState) yield key;
    })()
  );

  return function useForm(initialValues?: Readonly<Fields>): {
    fields: FormFields<Fields>;
    valid: boolean;
  } {
    type Action<
      K extends keyof typeof defaultState = keyof typeof defaultState
    > = ["reset", undefined | K] | ["touch", K] | ["set", K, string];

    const [state, dispatch] = useReducer(
      (prevState: typeof defaultState, action: Action): typeof defaultState => {
        switch (action[0]) {
          case "reset":
            return action[1] === undefined
              ? defaultState
              : {
                  ...prevState,
                  [action[1]]: defaultState[action[1]],
                };
          case "touch":
            const { rawValue, ...prevFieldState } = prevState[action[1]];

            return {
              ...prevState,
              [action[1]]: {
                ...prevFieldState,
                touched: true,
              },
            };
          case "set":
            return {
              ...prevState,
              [action[1]]: {
                value: parsers[action[1]](action[2]),
                rawValue: action[2],
              },
            };
        }
      },
      useReferentiallyStableMemo(
        () =>
          initialValues !== undefined
            ? formStateFromValues(initialValues)
            : undefined,
        []
      ) ?? defaultState
    );

    const inputProps = useRef(
      Object.fromEntries(
        stateKeys.map((key) => [
          key,
          {
            onBlur: (e: { readonly target: { readonly value: string } }) =>
              dispatch(["touch", key]),
            onChange: (e: { readonly target: { readonly value: string } }) =>
              dispatch(["set", key, e.target.value]),
          },
        ])
      ) as { [K in keyof Fields]: FormFields<Fields>[K]["inputProps"] }
    ).current;

    return useReferentiallyStableMemo(() => {
      const result: Partial<FormFields<Fields>> = {};

      let formValid = true;

      for (let i = 0; i < stateKeys.length; i++) {
        const key = stateKeys[i];
        const errorMessage = validators[key](state[key].value, state);
        const valid = errorMessage === undefined;
        formValid = formValid && valid;

        result[key] = {
          ...state[key],
          inputProps: inputProps[key],
          errorMessage,
          valid,
        };
      }

      return { fields: result as FormFields<Fields>, valid: formValid };
    }, [state, inputProps]);
  };
}
