import { Grid, GridItem, Tag, Text } from "@chakra-ui/react";
import Locations from "./Locations";
import { SensorStatusDisplay } from "./SensorStatusDisplay";
import Table from "./Table";
import { SensorStatus } from "./types";

const outageCount: number = 2;

export default function Sensors() {
  return (
    <Grid
      width="100%"
      gap={[1, 1, 4]}
      templateColumns={["1fr", "1fr", "3fr 1fr"]}
      gridTemplateAreas={[
        '"table" "context"',
        '"table" "context"',
        '"table context"',
      ]}
    >
      <GridItem
        area="table"
        overflowX="hidden"
        as={Grid}
        templateAreas={[
          `"header" "indicator" "table"`,
          `"header indicator" "table table"`,
        ]}
      >
        <GridItem area="header">
          <Text fontSize="x-large">Sensoren</Text>
        </GridItem>
        <GridItem area="indicator">
          <Tag size="lg" float="inline-end" width={["100%", "unset"]}>
            {outageCount === 0 ? (
              <>
                <SensorStatusDisplay marginRight={1}>
                  {SensorStatus.Ok}
                </SensorStatusDisplay>
                Geen storingen
              </>
            ) : (
              <>
                <SensorStatusDisplay marginRight={1}>
                  {SensorStatus.Warning}
                </SensorStatusDisplay>
                {outageCount} storing{outageCount !== 0 && "en"}
              </>
            )}
          </Tag>
        </GridItem>
        <GridItem area="table" width="100%" overflowX={"scroll"}>
          <Table />
        </GridItem>
      </GridItem>
      <GridItem area="context">
        <Locations />
      </GridItem>
    </Grid>
  );
}
