export enum SensorStatus {
  Error,
  Warning,
  Ok,
}

export interface Sensor {
  name: string;
  location: string;
  temperature: number | undefined;
  lastReadingTime: Date | undefined;
  id: string;
  status: SensorStatus;
}
