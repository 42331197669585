import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  VStack,
} from "@chakra-ui/react";
import { FormEventHandler, useCallback } from "react";
import { useLocation } from "wouter";
import SelectCountry, {
  Country,
  parseCountry,
} from "../../common/SelectCountry";
import createFormHook from "../../common/createFormHook";
import {
  dateParser,
  emailAdressValidator,
  noopParser,
  numberFieldParser,
  phoneNumberValidator,
} from "../../common/useFormField";

interface FormValues {
  name: string;
  licenses: number | undefined;
  email: string;
  phone: string;
  address: string;
  expiry: Date;
  country: Country | undefined;
}

const useCreationForm = createFormHook<FormValues>(
  {
    name: "",
    licenses: 0,
    email: "",
    phone: "",
    address: "",
    expiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    country: Country.Netherlands,
  },
  {
    name: noopParser,
    licenses: numberFieldParser,
    email: noopParser,
    phone: noopParser,
    address: noopParser,
    expiry: dateParser,
    country: parseCountry,
  },
  {
    name(value) {
      if (value.length > 0) return undefined;
      else return "Een naam is verplicht";
    },
    licenses(value) {
      if (value === undefined) return "Geen heel getal";
      else if (value < 0) return "Het aantal licenties mag niet negatief zijn";
      else return undefined;
    },
    email: emailAdressValidator,
    phone: phoneNumberValidator,
    address() {
      return undefined;
    },
    expiry(value) {
      if (value > new Date()) return undefined;
      else return "De datum moet in de toekomst zijn";
    },
    country(value) {
      return value === undefined ? "Dit veld is verplicht" : undefined;
    },
  }
);

export default function CreationForm() {
  const { fields, valid: formValid } = useCreationForm();

  const [, setLocation] = useLocation();
  const handleSubmit = useCallback<FormEventHandler>(
    (e) => {
      e.preventDefault();

      if (formValid) setLocation("/");
    },
    [formValid, setLocation]
  );

  const cancel = useCallback(() => setLocation("/"), [setLocation]);

  return (
    <Center>
      <VStack
        gap={1}
        minWidth={["100%", "unset"]}
        as="form"
        onSubmit={handleSubmit}
      >
        <FormControl isInvalid={fields.name.touched && !fields.name.valid}>
          <FormLabel>Naam</FormLabel>
          <Input {...fields.name.inputProps} value={fields.name.value} />
          <FormErrorMessage>{fields.name.errorMessage}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={fields.licenses.touched && !fields.licenses.valid}
        >
          <FormLabel># Licenties</FormLabel>
          <NumberInput>
            <NumberInputField
              {...fields.licenses.inputProps}
              value={fields.licenses.value}
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormErrorMessage>{fields.licenses.errorMessage}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={fields.email.touched && !fields.email.valid}>
          <FormLabel>E-mail hoofdgebruiker</FormLabel>
          <Input
            type="email"
            {...fields.email.inputProps}
            value={fields.email.value}
          />
          <FormErrorMessage>{fields.email.errorMessage}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={fields.phone.touched && !fields.phone.valid}>
          <FormLabel>Telefoonnummer</FormLabel>
          <Input {...fields.phone.inputProps} value={fields.phone.value} />
          <FormErrorMessage>{fields.phone.errorMessage}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={fields.address.touched && !fields.address.valid}
        >
          <FormLabel>Adres</FormLabel>
          <Input {...fields.address.inputProps} value={fields.address.value} />
          <FormErrorMessage>{fields.address.errorMessage}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={fields.country.touched && !fields.country.valid}
        >
          <FormLabel>Land</FormLabel>
          <SelectCountry
            {...fields.country.inputProps}
            value={fields.country.value}
          />
          <FormErrorMessage>{fields.country.errorMessage}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={fields.expiry.touched && !fields.expiry.valid}>
          <FormLabel>Vervaldatum licenties</FormLabel>
          <Input
            type="date"
            {...fields.expiry.inputProps}
            value={
              fields.expiry.rawValue ??
              (() => {
                const day = ("0" + fields.expiry.value.getDate()).slice(-2);
                const month = (
                  "0" +
                  (fields.expiry.value.getMonth() + 1)
                ).slice(-2);

                return (
                  fields.expiry.value.getFullYear() + "-" + month + "-" + day
                );
              })()
            }
          />
          <FormErrorMessage>{fields.expiry.errorMessage}</FormErrorMessage>
        </FormControl>
        <Button
          colorScheme="blue"
          width="100%"
          type="submit"
          isDisabled={!formValid}
        >
          Organisatie aanmaken
        </Button>
        <Button width="100%" onClick={cancel}>
          Annuleren
        </Button>
      </VStack>
    </Center>
  );
}
