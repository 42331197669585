import {
  Icon as ChakraIcon,
  Table as ChakraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useTime } from "haakje";
import { DateTime } from "luxon";
import { FC, PropsWithChildren, useMemo } from "react";
import {
  MdEdit,
  MdInfo,
  MdLocationPin,
  MdSchedule,
  MdTag,
  MdThermostat,
} from "react-icons/md";
import { SensorStatusDisplay } from "./SensorStatusDisplay";
import { Sensor, SensorStatus } from "./types";

function HeaderWithIcon({
  icon: Icon,
  children,
}: PropsWithChildren<{ icon: FC }>) {
  return (
    <Th>
      <ChakraIcon as={Icon} marginRight={1} />
      {children}
    </Th>
  );
}

function ReadingTimeDisplay({
  children: rawValue,
}: {
  children: Date | undefined;
}) {
  const now = useTime("minute");
  const formattedValue = useMemo(() => {
    if (rawValue === undefined) return "Ø";

    const value = DateTime.fromJSDate(rawValue);
    const difference = value.diff(now);
    if (difference.as("years") >= 1) return value.toFormat("dd-MM-yyyy");
    else if (difference.as("hours") >= 24) return value.toFormat("dd-MM");
    else return value.toFormat("T");
  }, [rawValue, now]);

  return <span>{formattedValue}</span>;
}

const sensors: readonly Readonly<Sensor>[] = [
  {
    name: "Koelcel",
    location: "Keuken",
    temperature: 4.2,
    lastReadingTime: new Date(),
    id: "A840413771856E57",
    status: SensorStatus.Warning,
  },
  {
    name: "Vriescel",
    location: "Theater",
    temperature: -10,
    lastReadingTime: new Date(),
    id: "A8404166F182D448",
    status: SensorStatus.Ok,
  },
  {
    name: "Drankenkoelcel",
    location: "Theater",
    temperature: 6,
    lastReadingTime: new Date(),
    id: "A84041AA6182D434",
    status: SensorStatus.Error,
  },
];

export default function Table() {
  return (
    <ChakraTable>
      <Thead>
        <Tr>
          <HeaderWithIcon icon={MdEdit}>Naam</HeaderWithIcon>
          <HeaderWithIcon icon={MdLocationPin}>Locatie</HeaderWithIcon>
          <HeaderWithIcon icon={MdThermostat}>Temperatuur</HeaderWithIcon>
          <HeaderWithIcon icon={MdSchedule}>Laatste meetpunt</HeaderWithIcon>
          <HeaderWithIcon icon={MdTag}>ID</HeaderWithIcon>
          <HeaderWithIcon icon={MdInfo}>Status</HeaderWithIcon>
        </Tr>
      </Thead>
      <Tbody>
        {sensors.map((sensor) => (
          <Tr key={sensor.id}>
            <Td>{sensor.name}</Td>
            <Td>{sensor.location}</Td>
            <Td>{sensor.temperature}</Td>
            <Td>
              <ReadingTimeDisplay>{sensor.lastReadingTime}</ReadingTimeDisplay>
            </Td>
            <Td>{sensor.id}</Td>
            <Td>
              <SensorStatusDisplay>{sensor.status}</SensorStatusDisplay>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </ChakraTable>
  );
}
