import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, VStack } from "@chakra-ui/react";
import { useCallback } from "react";
import { useLocation } from "wouter";
import useOrganisationId from "../useOrganisationId";
import Table from "./Table";

export default function List() {
  const [, setLocation] = useLocation();
  const organisationId = useOrganisationId();

  const goToCreate = useCallback(
    () => setLocation(`/${organisationId}/users/create`),
    [setLocation, organisationId]
  );

  return (
    <VStack width="100%" alignItems="end">
      <Button colorScheme="blue" leftIcon={<AddIcon />} onClick={goToCreate}>
        Aanmaken
      </Button>
      <Box width="100%" overflowX="scroll">
        <Table />
      </Box>
    </VStack>
  );
}
