import { AddIcon } from "@chakra-ui/icons";
import { Button, Grid, GridItem, Text } from "@chakra-ui/react";
import Table from "./Table";

const LAYOUT_SMALL = `
"header"
"button"
"table"`;

const LAYOUT_LARGE = `"header button" "table table"`;

export default function Locations() {
  return (
    <Grid width="100%" templateAreas={[LAYOUT_SMALL, LAYOUT_LARGE]}>
      <GridItem area="header">
        <Text fontSize="x-large">Locaties</Text>
      </GridItem>
      <GridItem area="button">
        <Button colorScheme="blue" float="inline-end" width={["100%", "unset"]}>
          <AddIcon marginRight={1} />
          Aanmaken
        </Button>
      </GridItem>
      <GridItem area="table" overflowX="scroll">
        <Table />
      </GridItem>
    </Grid>
  );
}
