import { Select, SelectProps } from "@chakra-ui/react";
import { orderBy } from "lodash";

export enum Country {
  Belgium = "BE",
  Bulgaria = "BG",
  Cyprus = "CY",
  Denmark = "DK",
  Germany = "DE",
  Estonia = "EE",
  Finland = "FI",
  France = "FR",
  Greece = "GR",
  Hungary = "HU",
  RepublicOfIreland = "IE",
  Iceland = "IS",
  Italy = "IT",
  Croatia = "HR",
  Latvia = "LV",
  Liechtenstein = "LI",
  Lithuania = "LT",
  Luxembourg = "LU",
  Malta = "MT",
  Netherlands = "NL",
  Norway = "NO",
  Austria = "AT",
  Poland = "PL",
  Portugal = "PT",
  Romania = "RO",
  Slovenia = "SI",
  Slovakia = "SK",
  Spain = "ES",
  Czechia = "CZ",
  Sweden = "SE",
  Switzerland = "CH",
  England = "GB-ENG",
  Wales = "GB-CYM",
  Scotland = "GB-SCT",
  NorthernIreland = "GB-NIR",
  Faroe = "FO",
  Greenland = "GL",
  Ukraine = "UA",
  Georgia = "GE",
  Moldova = "MD",
  Turkiye = "TR",
}

export const countryNames: Readonly<Record<Country, string>> = {
  [Country.Austria]: "Oostenrijk",
  [Country.Belgium]: "Belgie",
  [Country.Bulgaria]: "Bulgarijë",
  [Country.Croatia]: "Kroatië",
  [Country.Cyprus]: "Cyprus",
  [Country.Czechia]: "Tsjechië",
  [Country.Denmark]: "Denemarken",
  [Country.England]: "Engeland",
  [Country.Estonia]: "Estland",
  [Country.Faroe]: "Faröer",
  [Country.Finland]: "Finland",
  [Country.France]: "Frankrijk",
  [Country.Georgia]: "Georgië",
  [Country.Germany]: "Duitsland",
  [Country.Greece]: "Griekenland",
  [Country.Greenland]: "Groenland",
  [Country.Hungary]: "Hongarijë",
  [Country.Iceland]: "IJsland",
  [Country.Italy]: "Italië",
  [Country.Latvia]: "Letland",
  [Country.Liechtenstein]: "Liechtenstein",
  [Country.Lithuania]: "Litouwen",
  [Country.Luxembourg]: "Luxemburg",
  [Country.Malta]: "Malta",
  [Country.Moldova]: "Moldavië",
  [Country.Netherlands]: "Nederland",
  [Country.NorthernIreland]: "Noord-Ierland",
  [Country.Norway]: "Noorwegen",
  [Country.Poland]: "Polen",
  [Country.Portugal]: "Portugal",
  [Country.RepublicOfIreland]: "Rebubliek van Ierland",
  [Country.Romania]: "Roemenië",
  [Country.Scotland]: "Schotland",
  [Country.Slovakia]: "Slowakijë",
  [Country.Slovenia]: "Slovenië",
  [Country.Spain]: "Spanje",
  [Country.Sweden]: "Zweden",
  [Country.Switzerland]: "Zwitserland",
  [Country.Turkiye]: "Turkijë",
  [Country.Ukraine]: "Oekraïne",
  [Country.Wales]: "Wales",
};

export const ALPHABETICALLY_SORTED_COUNTRIES: readonly Country[] = orderBy(
  Array.from(
    (function* () {
      for (const key in countryNames)
        yield [key as Country, countryNames[key as Country]] as const;
    })()
  ),
  1
).map(([countryCode]) => countryCode);

export function isCountry(value: string): value is Country {
  return (ALPHABETICALLY_SORTED_COUNTRIES as readonly string[]).includes(value);
}

export function parseCountry(value: string): Country | undefined {
  return isCountry(value) ? value : undefined;
}

export default function SelectCountry(
  props: SelectProps & { value?: Country }
) {
  return (
    <Select {...props}>
      {ALPHABETICALLY_SORTED_COUNTRIES.map((countryCode) => (
        <option value={countryCode}>{countryNames[countryCode]}</option>
      ))}
    </Select>
  );
}
