import { isValidPhoneNumber } from "libphonenumber-js/mobile";
import { ChangeEventHandler, useCallback, useMemo, useState } from "react";

export default function useFormField<T>(
  defaultValue: T,
  parser: (rawValue: string) => T,
  validator: (value: T) => string | undefined
) {
  const [value, setValue] = useState(defaultValue);
  const [touched, setTouched] = useState(false);
  const errorMessage = useMemo(() => validator(value), [validator, value]);

  return {
    value,
    touched,
    valid: errorMessage == null,
    errorMessage,
    inputProps: {
      onBlur: useCallback(() => setTouched(true), [setTouched]),
      onChange: useCallback(
        (e) => setValue(parser(e.target.value)),
        [setValue, parser]
      ) satisfies ChangeEventHandler<HTMLInputElement>,
    },
    reset: useCallback(() => {
      setValue(defaultValue);
      setTouched(false);
    }, [setValue, defaultValue, setTouched]),
  };
}

export function numberFieldParser(rawValue: string) {
  const value = parseInt(rawValue);

  if (isNaN(value)) return undefined;
  else return value;
}

export function emailAdressValidator(email: string): string | undefined {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    ? undefined
    : "Voer een geldig e-mailadres in";
}

export function phoneNumberValidator(phoneNumber: string): string | undefined {
  if (isValidPhoneNumber(phoneNumber, "NL")) return undefined;
  else return "Ongeldig telefoonnummer";
}

export function noopParser(rawValue: string) {
  return rawValue;
}

export function dateParser(rawValue: string) {
  return new Date(rawValue);
}
