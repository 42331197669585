import { Route, Switch } from "wouter";
import Create from "./Create";
import List from "./List";
import Update from "./Update";

export default function Users() {
  return (
    <Switch>
      <Route path="/:organisationId/users" component={List} />
      <Route path="/:organisationId/users/create" component={Create} />
      <Route path="/:organisationId/users/:userId" component={Update} />
    </Switch>
  );
}
