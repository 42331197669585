import { Box, Flex, Stack } from "@chakra-ui/react";
import Create from "./Create";
import Search from "./Search";
import Table from "./Table";

export default function List() {
  return (
    <Stack gap={1} width="100%">
      <Flex
        gap={1}
        flexDir={["column-reverse", "row"]}
        justifyContent={["unset", "space-between"]}
      >
        <Box as={Search} />
        <Box as={Create} />
      </Flex>
      <Box overflowX="scroll">
        <Table />
      </Box>
    </Stack>
  );
}
