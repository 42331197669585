import { VStack } from "@chakra-ui/react";
import { Route, Switch } from "wouter";
import Banner from "./Banner";
import Edit from "./Edit";
import Sensors from "./Sensors";
import Users from "./Users";

export default function Organisation() {
  return (
    <VStack width="100%">
      <Banner />
      <Switch>
        <Route path="/:organisationId" component={Edit} />
        <Route path="/:organisationId/users.*" component={Users} />
        <Route path="/:organisationId/sensors.*" component={Sensors} />
      </Switch>
    </VStack>
  );
}
