import { useCallback } from "react";
import { useLocation } from "wouter";
import useOrganisationId from "../useOrganisationId";
import Form from "./Form";
import { PermissionLevel, User } from "./types";

const dummyUser: Readonly<User> = {
  id: 1,
  username: "Tess Ellenoir Duursma",
  email: "tess@albird.nl",
  locations: PermissionLevel.Delete,
  sensors: PermissionLevel.Delete,
  tasks: PermissionLevel.View,
  notifications: PermissionLevel.Edit,
};

export default function Update() {
  const [, setLocation] = useLocation();
  const organisationId = useOrganisationId();

  const goBack = useCallback(
    () => setLocation(`/${organisationId}/users`),
    [setLocation, organisationId]
  );

  return <Form submitLabel="Opslaan" onSubmit={goBack} user={dummyUser} />;
}
