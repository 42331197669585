import { DeleteIcon, EditIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Table as ChakraTable,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Link } from "wouter";
import useOrganisationId from "../useOrganisationId";
import { PermissionLevel, User, permissionLevelNames } from "./types";

function PermissionLevelDisplay({ children }: { children: PermissionLevel }) {
  return (
    <Tag colorScheme="blue">
      {
        (
          [
            [
              <ViewIcon marginRight={1} />,
              permissionLevelNames[PermissionLevel.View],
            ],
            [
              <EditIcon marginRight={1} />,
              permissionLevelNames[PermissionLevel.Edit],
            ],
            [
              <DeleteIcon marginRight={1} />,
              permissionLevelNames[PermissionLevel.Delete],
            ],
          ] as const
        )[children]
      }
    </Tag>
  );
}

const users: readonly User[] = [
  {
    id: 0,
    username: "Tess Ellenoir Duursma",
    email: "tess@albird.nl",
    locations: PermissionLevel.Delete,
    sensors: PermissionLevel.Delete,
    tasks: PermissionLevel.View,
    notifications: PermissionLevel.Edit,
  },
];

export default function Table() {
  const organisationId = useOrganisationId();

  return (
    <ChakraTable>
      <Thead>
        <Tr>
          <Th>Naam</Th>
          <Th>E-mailadres</Th>
          <Th>Locaties</Th>
          <Th>Sensoren</Th>
          <Th>Taken</Th>
          <Th>Notificaties</Th>
        </Tr>
      </Thead>
      <Tbody>
        {users.map((user) => (
          <Tr key={user.id}>
            <Td>
              <Link to={`/${organisationId}/users/${user.id}`}>
                {user.username}
              </Link>
            </Td>
            <Td>{user.email}</Td>
            <Td>
              <PermissionLevelDisplay>{user.locations}</PermissionLevelDisplay>
            </Td>
            <Td>
              <PermissionLevelDisplay>{user.sensors}</PermissionLevelDisplay>
            </Td>
            <Td>
              <PermissionLevelDisplay>{user.tasks}</PermissionLevelDisplay>
            </Td>
            <Td>
              <PermissionLevelDisplay>
                {user.notifications}
              </PermissionLevelDisplay>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </ChakraTable>
  );
}
