import { Box, ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { Redirect, Route, Router } from "wouter";
import Organisations from "./Organisations";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <Box width="100%" padding={2}>
        <Router>
          <Route path="/">
            <Redirect to="/organisations" replace />
          </Route>
          <Organisations />
        </Router>
      </Box>
    </ChakraProvider>
  </React.StrictMode>
);
