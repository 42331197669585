import { AddIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { useLocation } from "wouter";

export default function Create() {
  const [, setLocation] = useLocation();

  return (
    <Button
      colorScheme="blue"
      minWidth="fit-content"
      leftIcon={<AddIcon />}
      onClick={() => setLocation("/create")}
    >
      Organisatie aanmaken
    </Button>
  );
}
