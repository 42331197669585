import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Grid, GridItem } from "@chakra-ui/layout";
import { FormErrorMessage, Select } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/textarea";
import SelectCountry, {
  Country,
  parseCountry,
} from "../../common/SelectCountry";
import SelectLanguage, {
  Language,
  parseLanguage,
} from "../../common/SelectLanguage";
import createFormHook from "../../common/createFormHook";
import {
  dateParser,
  emailAdressValidator,
  noopParser,
  numberFieldParser,
  phoneNumberValidator,
} from "../../common/useFormField";

const LAYOUT_LARGE = `
"name contact country"
"phone email language"
"address licenses invoice"
"start expiry notes"
"tag save notes"`;

const LAYOUT_SMALL = `
"name"
"contact"
"phone"
"email"
"address"
"country"
"language"
"licenses"
"start"
"expiry"
"invoice"
"notes"
"notes"
"tag"
"save"`;

enum InvoiceStatus {
  PAID = "paid",
  PROCESSING = "processing",
  EXPIRED = "expired",
}

interface EditorData {
  name: string;
  contactName: string;
  phone: string;
  email: string;
  address: string;
  country: Country | undefined;
  language: Language | undefined;
  licenses: number | undefined;
  licenseStart: Date;
  licenseExpiry: Date;
  invoiceStatus: InvoiceStatus | undefined;
  notes: string;
  tag: string;
}

const DEFAULTS: Readonly<EditorData> = {
  name: "",
  contactName: "",
  phone: "",
  email: "",
  address: "",
  country: Country.Netherlands,
  language: Language.Dutch,
  licenses: 0,
  licenseStart: new Date(),
  licenseExpiry: new Date(),
  invoiceStatus: undefined,
  notes: "",
  tag: "",
};

const MANDATORY_STRING = (value: string): string | undefined =>
  value.length > 0 ? undefined : "Dit veld is verplicht";

const MANDATORY_POSITIVE_NUMBER = (
  value: number | undefined
): string | undefined =>
  value === undefined
    ? "Dit veld is verplicht"
    : value < 0
    ? "De waarde moet positief zijn"
    : undefined;

const NO_VALIDATION = () => undefined;

function isInvoiceStatus(value: string): value is InvoiceStatus {
  const INVOICE_STATUSES: readonly string[] = Object.values(InvoiceStatus);

  return INVOICE_STATUSES.includes(value);
}

function parseInvoiceStatus(value: string): InvoiceStatus | undefined {
  return isInvoiceStatus(value) ? value : undefined;
}

const useEditorForm = createFormHook(
  DEFAULTS,
  {
    name: noopParser,
    contactName: noopParser,
    phone: noopParser,
    email: noopParser,
    address: noopParser,
    country: parseCountry,
    language: parseLanguage,
    licenses: numberFieldParser,
    licenseStart: dateParser,
    licenseExpiry: dateParser,
    invoiceStatus: parseInvoiceStatus,
    notes: noopParser,
    tag: noopParser,
  },
  {
    name: MANDATORY_STRING,
    contactName: MANDATORY_STRING,
    phone: phoneNumberValidator,
    email: emailAdressValidator,
    address: MANDATORY_STRING,
    country(value) {
      if (value === undefined) return "Dit veld is verplicht";
      else return undefined;
    }, // TODO: Add validation from a list of countries?
    language(value) {
      if (value === undefined) return "Dit veld is verplicht";
      else return undefined;
    },
    licenses: MANDATORY_POSITIVE_NUMBER,
    // TODO: Add the ability to read other fields for validation
    licenseStart(value, state) {
      if (value < state.licenseExpiry.value) return undefined;
      else return "Deze datum moet voor de vervaldatum liggen";
    },
    licenseExpiry(value, state) {
      if (value > state.licenseStart.value) return undefined;
      else return "Deze datum moet na de startdatum liggen";
    },
    invoiceStatus: NO_VALIDATION,
    notes: NO_VALIDATION,
    // TODO: Determine whether tags require validation
    tag: NO_VALIDATION,
  }
);
export default function Edit() {
  const { fields, valid } = useEditorForm();

  return (
    <Grid
      templateAreas={[LAYOUT_SMALL, LAYOUT_LARGE]}
      templateColumns={["1fr", "repeat(3, 1fr)"]}
      templateRows={["repeat(15, 1fr)", "repeat(5, 1fr)"]}
      gap={1}
      width="100%"
    >
      {/* Org name */}
      <GridItem
        w="100%"
        area="name"
        as={FormControl}
        isInvalid={fields.name.touched && !fields.name.valid}
      >
        <FormLabel>Naam</FormLabel>
        <Input {...fields.name.inputProps} value={fields.name.value} />
        <FormErrorMessage>{fields.name.errorMessage}</FormErrorMessage>
      </GridItem>
      {/* Contact person name */}
      <GridItem
        w="100%"
        area="contact"
        as={FormControl}
        isInvalid={fields.contactName.touched && !fields.contactName.valid}
      >
        <FormLabel>Contactpersoon</FormLabel>
        <Input
          {...fields.contactName.inputProps}
          value={fields.contactName.value}
        />
        <FormErrorMessage>{fields.contactName.errorMessage}</FormErrorMessage>
      </GridItem>
      {/* Country */}
      <GridItem
        w="100%"
        area="country"
        as={FormControl}
        isInvalid={fields.country.touched && !fields.country.valid}
      >
        <FormLabel>Land</FormLabel>
        <SelectCountry
          {...fields.country.inputProps}
          value={fields.country.value}
        />
        <FormErrorMessage>{fields.country.errorMessage}</FormErrorMessage>
      </GridItem>
      {/* Phone number */}
      <GridItem
        w="100%"
        area="phone"
        as={FormControl}
        isInvalid={fields.phone.touched && !fields.phone.valid}
      >
        <FormLabel>Telefoonnummer</FormLabel>
        <Input {...fields.phone.inputProps} value={fields.phone.value} />
        <FormErrorMessage>{fields.phone.errorMessage}</FormErrorMessage>
      </GridItem>
      {/* Email address */}
      <GridItem
        w="100%"
        area="email"
        as={FormControl}
        isInvalid={fields.email.touched && !fields.email.valid}
      >
        <FormLabel>E-mailadres</FormLabel>
        <Input {...fields.email.inputProps} value={fields.email.value} />
        <FormErrorMessage>{fields.email.errorMessage}</FormErrorMessage>
      </GridItem>
      {/* Language */}
      <GridItem
        w="100%"
        area="language"
        as={FormControl}
        isInvalid={fields.language.touched && !fields.language.valid}
      >
        <FormLabel>Taal</FormLabel>
        <SelectLanguage
          {...fields.language.inputProps}
          value={fields.language.value}
        />
        <FormErrorMessage>{fields.language.errorMessage}</FormErrorMessage>
      </GridItem>
      {/* Address */}
      <GridItem
        w="100%"
        area="address"
        as={FormControl}
        isInvalid={fields.address.touched && !fields.address.valid}
      >
        <FormLabel>Adres</FormLabel>
        <Input {...fields.address.inputProps} value={fields.address.value} />
        <FormErrorMessage>{fields.address.errorMessage}</FormErrorMessage>
      </GridItem>
      {/* License count */}
      <GridItem
        w="100%"
        area="licenses"
        as={FormControl}
        isInvalid={fields.licenses.touched && !fields.licenses.valid}
      >
        <FormLabel># Licenties</FormLabel>
        <Input {...fields.licenses.inputProps} value={fields.licenses.value} />
        <FormErrorMessage>{fields.licenses.errorMessage}</FormErrorMessage>
      </GridItem>
      {/* Last invoice */}
      <GridItem
        w="100%"
        area="invoice"
        as={FormControl}
        isInvalid={fields.invoiceStatus.touched && !fields.invoiceStatus.valid}
      >
        <FormLabel>Laatste factuur</FormLabel>
        <Select
          {...fields.invoiceStatus.inputProps}
          value={fields.invoiceStatus.value}
        >
          <option value={undefined} />
          <option value={InvoiceStatus.PROCESSING}>In verwerking</option>
          <option value={InvoiceStatus.PAID}>Betaald</option>
          <option value={InvoiceStatus.EXPIRED}>Verlopen</option>
        </Select>
        <FormErrorMessage>{fields.invoiceStatus.errorMessage}</FormErrorMessage>
      </GridItem>
      {/* Start date */}
      <GridItem
        w="100%"
        area="start"
        as={FormControl}
        isInvalid={fields.licenseStart.touched && !fields.licenseStart.valid}
      >
        <FormLabel>Startdatum</FormLabel>
        <Input
          {...fields.licenseStart.inputProps}
          value={
            fields.licenseStart.rawValue ??
            (() => {
              const day = ("0" + fields.licenseStart.value.getDate()).slice(-2);
              const month = (
                "0" +
                (fields.licenseStart.value.getMonth() + 1)
              ).slice(-2);

              return (
                fields.licenseStart.value.getFullYear() +
                "-" +
                month +
                "-" +
                day
              );
            })()
          }
          type="date"
        />
        <FormErrorMessage>{fields.licenseStart.errorMessage}</FormErrorMessage>
      </GridItem>
      {/* Expiry date */}
      <GridItem
        w="100%"
        area="expiry"
        as={FormControl}
        isInvalid={fields.licenseExpiry.touched && !fields.licenseExpiry.valid}
      >
        <FormLabel>Vervaldatum</FormLabel>
        <Input
          {...fields.licenseExpiry.inputProps}
          value={
            fields.licenseExpiry.rawValue ??
            (() => {
              const day = ("0" + fields.licenseExpiry.value.getDate()).slice(
                -2
              );
              const month = (
                "0" +
                (fields.licenseExpiry.value.getMonth() + 1)
              ).slice(-2);

              return (
                fields.licenseExpiry.value.getFullYear() +
                "-" +
                month +
                "-" +
                day
              );
            })()
          }
          type="date"
        />
        <FormErrorMessage>{fields.licenseExpiry.errorMessage}</FormErrorMessage>
      </GridItem>
      {/* Notes */}
      <GridItem
        w="100%"
        h="100%"
        area="notes"
        as={FormControl}
        flexDir="column"
        isInvalid={fields.notes.touched && !fields.notes.valid}
      >
        <FormLabel>Aantekeningen</FormLabel>
        <Textarea {...fields.notes.inputProps} value={fields.notes.value} />
        <FormErrorMessage>{fields.notes.errorMessage}</FormErrorMessage>
      </GridItem>
      {/* Tag */}
      <GridItem
        w="100%"
        area="tag"
        as={FormControl}
        isInvalid={fields.tag.touched && !fields.tag.valid}
      >
        <FormLabel>Tag</FormLabel>
        <Input {...fields.tag.inputProps} value={fields.tag.value} />
        <FormErrorMessage>{fields.tag.errorMessage}</FormErrorMessage>
      </GridItem>
      {/* Save */}
      <GridItem w="100%" area="save">
        <FormLabel>&nbsp;</FormLabel>
        <Button w="100%" colorScheme="blue" isDisabled={!valid}>
          Opslaan
        </Button>
      </GridItem>
    </Grid>
  );
}
