export enum PermissionLevel {
  View = 0,
  Edit = 1,
  Delete = 2,
}

export const PERMISSION_LEVELS = [
  PermissionLevel.View,
  PermissionLevel.Edit,
  PermissionLevel.Delete,
] as const;

export const permissionLevelNames: Readonly<Record<PermissionLevel, string>> = [
  "Weergeven",
  "Bewerken",
  "Verwijderen",
];

export function isPermissionLevel(value: number): value is PermissionLevel {
  return (PERMISSION_LEVELS as readonly number[]).includes(value);
}

export function parsePermissionLevel(
  value: string
): PermissionLevel | undefined {
  const valueAsNumber = parseInt(value);
  if (isNaN(valueAsNumber)) return undefined;
  else return isPermissionLevel(valueAsNumber) ? valueAsNumber : undefined;
}

export interface User {
  id: number;
  username: string;
  email: string;
  locations: PermissionLevel;
  sensors: PermissionLevel;
  tasks: PermissionLevel;
  notifications: PermissionLevel;
}
