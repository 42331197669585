import {
  Badge,
  Table as ChakraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { Link } from "wouter";
import { useQuery } from "./Search";

const organisations: readonly Readonly<{
  id: number;
  name: string;
  lastLogin: string;
  outages: number;
  lastReport: string;
  tag: string;
}>[] = [
  {
    id: 1,
    name: "De Pleats",
    lastLogin: "18:19 23-10",
    outages: 1,
    lastReport: "23-10-2023",
    tag: "Horeca",
  },
  {
    id: 2,
    name: "Appie",
    lastLogin: "09-11-2023",
    outages: 11,
    lastReport: "23-10-2023",
    tag: "Horeca",
  },
  {
    id: 3,
    name: "Alliade",
    lastLogin: "11-11-2023",
    outages: 0,
    lastReport: "23-10-2023",
    tag: "Zorg",
  },
];

export default function Table() {
  const [query] = useQuery();
  const filteredOrganisations = useMemo(() => {
    const normalizedQuery = query.toLowerCase();

    return organisations.filter(({ name }) =>
      name.toLowerCase().includes(normalizedQuery)
    );
  }, [query, organisations]);

  return (
    <ChakraTable>
      <Thead>
        <Tr>
          <Th>Organisatie</Th>
          <Th>Laatste login</Th>
          <Th>#&nbsp;Storingen</Th>
          <Th>Laatste rapport</Th>
          <Th>Tag</Th>
        </Tr>
      </Thead>
      <Tbody>
        {filteredOrganisations.map((organisation) => (
          <Tr key={organisation.id}>
            <Td>
              <Link to={`/${organisation.id}`}>{organisation.name}</Link>
            </Td>
            <Td>{organisation.lastLogin}</Td>
            <Td>
              {organisation.outages === 0
                ? "In orde"
                : organisation.outages === 1
                ? "1 storing"
                : `${organisation.outages} storingen`}
            </Td>
            <Td>{organisation.lastReport}</Td>
            <Td>
              <Badge colorScheme="blue">{organisation.tag}</Badge>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </ChakraTable>
  );
}
